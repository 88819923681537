import "../scss/style.scss";
import "../js/splide.js";
import "../js/works.js";
import "jquery-match-height";

//smooth scroll
$(function () {
  var headerHeight = $('.header').outerHeight();
  var urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function() {
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({
        scrollTop: position
      }, 500);
    }, 100);
  }
	$('a[href^="#"]').click(function () {
		var headerH = $('.js-header').outerHeight();
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? "body" : href);
		var position = target.offset().top - headerH;
		$("html, body").animate({ scrollTop: position }, 800, "swing");
		return false;
	});
});

// header fixed
$(window).on("load scroll", function(){
	$(".js-header").css("left", -$(window).scrollLeft());
});
$(function(){
	var headerH02 = $('.js-header').outerHeight();
	$('body').css('padding-top', headerH02 + 'px');
});

// pagetop
$(function(){
	$(window).scroll(function () {
		if($(this).scrollTop() >= 600) {
			$('.js-pagetop').fadeIn();
		} else {
			$('.js-pagetop').fadeOut();
		}
		var scrollHeight = $(document).height();
		var scrollPosition = $(window).height() + $(window).scrollTop();
		var footerHeight = $(".js-footer").innerHeight();
		if ( scrollHeight - scrollPosition  <= footerHeight - 45 ) {
			$(".js-pagetop").addClass('is-stop');
		} else {
			$(".js-pagetop").removeClass('is-stop');
		}
	});
});

$(document).ready(function () {
	$('.navBtn').on('click', function () {
		if ($('#nav').hasClass('close')) {
			$("#nav").removeClass('close');
			$(this).removeClass('close');
		} else {
			$("#nav").addClass('close');
			$(this).addClass('close');
		}
	});
	$('.navBtn.close, .js-navsub-dropdown li a').on('click', function () {
		$("#nav").removeClass('close');
		$('.navBtn.close').removeClass('close');
	});
});

// tab
$(function() {
  $('.js-tab-btn__item').click(function() {
    var index = $(this).parent().children('.js-tab-btn__item').index(this);
    $(this).parent().children('.js-tab-btn__item').removeClass('is-active');
    $(this).addClass('is-active');
    $(this).parents('.js-tab-set').find('.js-tab-content').removeClass('is-show').eq(index).addClass('is-show');
  });
});

$(function() {
	$('.js-matchheight').matchHeight();
});

// プルダウン
$(function() {
	if (window.matchMedia("(max-width: 768px)").matches) {
		$('.js-nav-dropdown > a').on('click', function (event) {
			event.preventDefault();
			$(this).toggleClass('is_open');
			$('.js-navsub-dropdown').slideToggle();
		});
	}
});
