import "magnific-popup";

$(function() {
  $('.js-taglist li a').on('click', function () {
    $('.js-taglist li a').removeClass('is_active');
    $(this).addClass('is_active');
    var tag = $(this).attr('data-tag');
    if(tag === 'all') {
      $('.worksSec02__list li').show();
    } else {
      $('.worksSec02__list li').hide();
      $('.worksSec02__list li[data-tag="' + tag + '"]').show();
    }
  });

  $('.js-popup').magnificPopup({
    type:'inline',
    gallery: {
      enabled: true
    }
  });
});
