import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

if($('.splide.homeSec03__splideBox').length > 0) {
  $(function () {
    const options = {
    fixedWidth: "270px",
    type: "loop",
    gap: '0',
    arrows: false,
    drag: "free",
    flickPower: 100,
    pagination: false,
    autoScroll: {
      speed: 0.4,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
  };
  const splide = new Splide(".splide.homeSec03__splideBox", options);
  splide.mount({AutoScroll});
  });
}

if($('.splide.serviceSec02__splideBox').length > 0) {
  $(function () {
    const options = {
    type: "slide",
    gap: '0',
    arrows: true,
    pagination: true,
  };
  const splide = new Splide(".splide.serviceSec02__splideBox", options);
  splide.mount();
  });
}

